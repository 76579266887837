<template>
  <div class="resource">
    <Head :headdata="headdata"></Head>
    <div class="resourceMain common-main">
      <h2 class="title_zh">
        主要客户
        <div class="fenxBox">
          <img class="fenx" src="../assets/images/icon_fenx.png" alt />
          <p>分享</p>
          <share />
        </div>
      </h2>
      <h2 class="title_en">MAJOR CLIENT</h2>

      <div class="resourceLists ">
        <ul class="clearfloat">
          <li v-for="(item,index) in resourceLists" :key="index">{{item.title}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Head from "@/components/header.vue";
import share from "@/components/share.vue";
export default {
  data() {
    return {
      headdata: this.$t("m.companyHeaddata2"),
      resourceLists: "",
    };
  },
  components: {
    Head,
    share
  },
  updated() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.axios
        .get(this.GLOBAL.serverSrc + "/wc/customs") // 真实环境中，后端会根据参数group返回新的图片数组，这里我用一个惊呆json文件模拟
        .then((res) => {
          if (res.data.code == 1) {
            this.resourceLists = res.data.data;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.resource {
  height: 100%;
  width: 100%;
  .resourceMain {
    width: 100%;
    padding: 78px 200px 0 200px;
    .title_zh {
      color: #000;
      font-size: 34px;
      .fenxBox {
        float: right;
        cursor: pointer;
        .fenx {
          width: 20px;
          height: 20px;
        }
        p {
          font-size: 12px;
          color: #808080;
        }
      }
    }
    .title_en {
      margin-bottom: 60px;
      font-size: 24px;
    }
    .resourceLists{
      width: 100%;
      margin-bottom:60px;
      ul{
        li{
          float: left;
          width: 25%;
          text-align: left;
          color: #000;
          font-size: 16px;
          margin-top: 30px;
        }
      }
    }
  }
}
@media (max-width: 768px) { 
  .resourceLists {
    margin-bottom:20px;
  }
}
</style>